<script setup lang="ts">
import { init } from './global/stores/global_stores'
import {  onMounted } from 'vue'


onMounted(() => {
  init()
})
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
