export const options = {
  "images": true,
  "videos": true,
  "audios": true,
  "iframes": true,
  "native": false,
  "directiveOnly": false,
  "defaultImage": false,
  "loadingClass": "isLoading",
  "loadedClass": "isLoaded",
  "appendClass": "lazyLoad",
  "observerConfig": {
    "rootMargin": "20px"
  }
}