import { default as index58fZLRSZWHMeta } from "/home/ubuntu/cameo-web/pages/index.vue?macro=true";
import { default as postproduction7Mp5PLmpEqMeta } from "/home/ubuntu/cameo-web/pages/postproduction.vue?macro=true";
export default [
  {
    name: index58fZLRSZWHMeta?.name ?? "index",
    path: index58fZLRSZWHMeta?.path ?? "/",
    meta: index58fZLRSZWHMeta || {},
    alias: index58fZLRSZWHMeta?.alias || [],
    redirect: index58fZLRSZWHMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/cameo-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: postproduction7Mp5PLmpEqMeta?.name ?? "postproduction",
    path: postproduction7Mp5PLmpEqMeta?.path ?? "/postproduction",
    meta: postproduction7Mp5PLmpEqMeta || {},
    alias: postproduction7Mp5PLmpEqMeta?.alias || [],
    redirect: postproduction7Mp5PLmpEqMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/cameo-web/pages/postproduction.vue").then(m => m.default || m)
  }
]