import revive_payload_client_UE9IrR5jWo from "/home/ubuntu/cameo-web/node_modules/.pnpm/nuxt@3.6.2_@types+node@18.16.19_eslint@8.44.0_typescript@4.9.5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_wmt6nx8PRc from "/home/ubuntu/cameo-web/node_modules/.pnpm/nuxt@3.6.2_@types+node@18.16.19_eslint@8.44.0_typescript@4.9.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_0ytiY0hvOb from "/home/ubuntu/cameo-web/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@4.9.5_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/ubuntu/cameo-web/.nuxt/components.plugin.mjs";
import unhead_9sv57Yzfqu from "/home/ubuntu/cameo-web/node_modules/.pnpm/nuxt@3.6.2_@types+node@18.16.19_eslint@8.44.0_typescript@4.9.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_DQZ8wdgf73 from "/home/ubuntu/cameo-web/node_modules/.pnpm/nuxt@3.6.2_@types+node@18.16.19_eslint@8.44.0_typescript@4.9.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_CKN1EBL8kA from "/home/ubuntu/cameo-web/node_modules/.pnpm/nuxt-lazy-load@3.0.4/node_modules/nuxt-lazy-load/dist/runtime/plugin.mjs";
import plugin_SjrWhxHRlu from "/home/ubuntu/cameo-web/node_modules/.pnpm/@nuxtjs+device@3.1.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_uI7FcRbvgL from "/home/ubuntu/cameo-web/node_modules/.pnpm/nuxt@3.6.2_@types+node@18.16.19_eslint@8.44.0_typescript@4.9.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_UE9IrR5jWo,
  router_wmt6nx8PRc,
  plugin_vue3_0ytiY0hvOb,
  components_plugin_KR1HBZs4kY,
  unhead_9sv57Yzfqu,
  prefetch_client_DQZ8wdgf73,
  plugin_CKN1EBL8kA,
  plugin_SjrWhxHRlu,
  chunk_reload_client_uI7FcRbvgL
]